/* eslint-disable no-param-reassign */
import { logger } from '@/store/logger';
import { ActionContext, Commit, Dispatch } from 'vuex';
import Api from '@/store/helpers/api';
import {
  storeState, storeStateGetters, storeStateMutations, storeStateActions, allowedStates,
} from '../helpers/storeState';
import {
  DocumentRequestsFilter, DocumentRequestsPage, Getters, State,
  DocumentRequestSummaryCounts,
} from './types';
import GETDocumentLinks from '../helpers/mockResponses/GETDocumentLinks';

const MAX_DOCUMENT_REQUESTS_PER_PAGE = 20;

const initialState = (): State => ({
  ...storeState,
  filter: {},
  orderBy: null,
  documentRequestsPage: null,
  documentRequestSummaryCounts: null,
  offset: 0,
  maxPageSize: MAX_DOCUMENT_REQUESTS_PER_PAGE,
});

const storeGetters: Getters = {
  ...storeStateGetters,
  documentRequestsPage: (state: State) => state.documentRequestsPage,
  documentRequestsPageIndex: (state: State) => (state.offset > 0
    ? (state.offset / state.maxPageSize) : 0),
  totalPages: (state: State) => (state.documentRequestsPage
    ? Math.ceil(state.documentRequestsPage.totalCount / state.maxPageSize)
    : null),
  isCurrentPageLastPage: (state: State) => {
    if (!state.documentRequestsPage) {
      return false;
    }
    const pageIndex = state.offset > 0 ? (state.offset / state.maxPageSize) : 0;
    const totalPages = Math.ceil(
      state.documentRequestsPage.totalCount / state.maxPageSize,
    );
    return totalPages === 0 ? true : pageIndex + 1 === totalPages;
  },
  filter: (state: State) => state.filter,
  offset: (state: State) => state.offset,
  orderBy: (state: State) => state.orderBy,
  documentRequestSummaryCounts: (state: State) => state.documentRequestSummaryCounts,
  maxPageSize: (state) => state.maxPageSize,

};

const store = {
  namespaced: true,
  state: {
    ...initialState(),
  },
  getters: storeGetters,
  mutations: {
    ...storeStateMutations,
    SET_DOCUMENT_REQUESTS_PAGE(state: State, page: DocumentRequestsPage) {
      state.documentRequestsPage = page;
      logger.debug('documentRequestsPage has been updated', state.documentRequestsPage);
    },
    SET_MAX_PAGE_SIZE(state: State, maxPageSize: number) {
      state.maxPageSize = maxPageSize;
      logger.debug('documents max page size has been updated', state.maxPageSize);
    },
    SET_FILTER(state: State, filter: DocumentRequestsFilter) {
      state.filter = filter;
      logger.debug('documentRequests filter has been updated', state.filter);
    },
    SET_OFFSET(state: State, offset: number) {
      // Round down the offset to the nearest multiple of state.maxPageSize
      // to ensures that the new offset corresponds to the beginning of a page.
      state.offset = Math.floor(
        offset / state.maxPageSize,
      ) * state.maxPageSize;
    },
    SET_ORDER_BY(state: State, orderBy: string) {
      state.orderBy = orderBy;
    },
    SET_DOCUMENT_REQUEST_SUMMARY_COUNTS(state: State,
      documentRequestSummaryCounts: DocumentRequestSummaryCounts) {
      state.documentRequestSummaryCounts = documentRequestSummaryCounts;
    },
    RESET(state: State) {
      logger.debug('Reseting state of documents store');
      Object.assign(state, initialState());
    },
  },

  actions: {
    ...storeStateActions,
    init: async ({ commit, dispatch }: {
      commit: Commit,
      getters: Getters,
      dispatch: Dispatch
    }, {
      filter = {},
      orderBy = null,
      page = 1,
      maxPageSize = MAX_DOCUMENT_REQUESTS_PER_PAGE,
    }: {
      filter: DocumentRequestsFilter, orderBy: string | null, page?: number, maxPageSize?: number,
    }): Promise<void> => {
      try {
        commit('RESET');
        commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
        logger.debug('Initializing documents store', filter);
        commit('SET_MAX_PAGE_SIZE', maxPageSize);
        commit('SET_OFFSET', (page - 1) * maxPageSize);
        commit('SET_FILTER', filter);
        commit('SET_ORDER_BY', orderBy);
        await dispatch('refreshDocumentRequestSummaryCounts');
        await dispatch('refreshCurrentPage');
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },

    getDocumentRequestSummaryCounts: async (
      { rootGetters }: ActionContext<State, any>,
      { stage }: { stage: string },
    ) => {
      logger.debug('Fetching summary counts for table headers');
      return (new Api(process.env, rootGetters['authenticate/idToken'])).get(`document-requests/summary-counts?stage=${stage}`);
    },

    // NOTE: document requests are PAGINATED.
    getDocumentRequests: async (
      { rootGetters }: ActionContext<State, any>,
      {
        offset,
        filter = {},
        orderBy = null,
        maxPageSize = MAX_DOCUMENT_REQUESTS_PER_PAGE,
      }: {
        offset: number,
        filter?: DocumentRequestsFilter,
        orderBy?: string | null,
        maxPageSize?: number,
      },
    ) => {
      const orderByValue = orderBy?.length ? orderBy : 'uploadedDate:desc';
      logger.debug(`Fetching document requests - orderBy: ${orderByValue}, filter: ${filter}`);
      const { stage, columnFilters, search } = filter;

      return (new Api(process.env, rootGetters['authenticate/idToken']))
        .get('document-requests', {
          maxPageSize,
          offset,
          orderBy: orderByValue,
          ...stage && { stage },
          ...search && { search },
          ...columnFilters,
        } as any);
    },

    getDocumentLinks: async (
      { rootGetters }: ActionContext<State, any>,
      { documentRequestId, fileName }: { documentRequestId: string, fileName: string },
    ) => {
      logger.debug('  ** Getting Document Links **  offline:', rootGetters['documentRequest/offline']);
      // If offline, retrieve local document download links (pdf and annotations)
      if (rootGetters['documentRequest/offline']) {
        return new Promise(((resolve) => {
          setTimeout(resolve, 500, GETDocumentLinks());
        }));
      }

      if (documentRequestId === null || documentRequestId === undefined) {
        throw Error('PDF does not exist');
      }
      logger.debug('env:', process.env);
      return (new Api(process.env, rootGetters['authenticate/idToken'])).get(`documentrequest/${documentRequestId}/document`, {
        ...fileName && { filename: fileName },
      } as any);
    },

    sortDocumentRequests: async (
      { dispatch, commit }: ActionContext<State, any>,
      { orderBy = null }: { orderBy: string | null },
    ) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      logger.debug('Sorting docment requests - ', orderBy);
      try {
        commit('SET_ORDER_BY', orderBy);
        await dispatch('refreshCurrentPage');
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },

    refreshCurrentPage: async (
      { getters, commit, dispatch }: ActionContext<State, any>,
    ) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      try {
        const response = await dispatch('getDocumentRequests', {
          offset: getters.offset,
          filter: getters.filter,
          orderBy: getters.orderBy,
          maxPageSize: getters.maxPageSize,
        });
        commit('SET_DOCUMENT_REQUESTS_PAGE', response);
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },

    refreshDocumentRequestSummaryCounts: async (
      { commit, dispatch, getters }: ActionContext<State, any>,
    ) => {
      logger.debug('Re-fetching the current header filters of document requests');
      try {
        const documentRequestSummaryCounts = await dispatch('getDocumentRequestSummaryCounts', { stage: getters.filter.stage });
        commit('SET_DOCUMENT_REQUEST_SUMMARY_COUNTS', documentRequestSummaryCounts);
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },

    filterDocumentRequests: async (
      { dispatch, commit, getters }: ActionContext<State, any>,
      filter: DocumentRequestsFilter,
    ) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      commit('SET_OFFSET', 0);
      logger.debug('Filtering document requests - the offset is set to 0');
      try {
        commit('SET_FILTER', { ...getters.filter, ...filter });
        await dispatch('refreshCurrentPage');
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        logger.debug('Failed to filter document requests', e);
        throw e;
      }
    },

    updateCurrentPageAndMaxPageSize: async (
      { dispatch, commit }: ActionContext<State, any>,
      { page, maxPageSize }: { page: number, maxPageSize: number },
    ) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      commit('SET_MAX_PAGE_SIZE', maxPageSize);
      commit('SET_OFFSET', (page - 1) * maxPageSize);
      try {
        await dispatch('refreshCurrentPage');
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        logger.debug('Failed to update page and maxPageSize for documents', e);
        throw e;
      }
    },

    nextPage: async (
      { getters, dispatch, commit }: ActionContext<State, any>,
    ) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      const disableNext = getters.isCurrentPageLastPage;
      // A null token is the token of the first page - prevent execution to avoid a loop.
      if (disableNext) {
        logger.debug('The current page is the last page, no update will be made as next page does not exist');
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
        return;
      }
      commit('SET_OFFSET', getters.offset + getters.maxPageSize);
      logger.debug('Fetching the next page of document requests - offset has been updated to ', getters.offset);
      try {
        await dispatch('refreshCurrentPage');
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },
    prevPage: async ({ getters, dispatch, commit }: ActionContext<State, any>) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      if (getters.documentRequestsPageIndex === 0) {
        logger.debug('The current page is the first page, no update will be made as previous page does not exist');
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
        return;
      }
      commit('SET_OFFSET', getters.offset - getters.maxPageSize);
      logger.debug('Fetching the previous page of document requests - offset has been updated to ', getters.offset);
      try {
        await dispatch('refreshCurrentPage');
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },

    jumpToPage: async ({
      getters, dispatch, commit,
    }: ActionContext<State, any>, pageNumber: number) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      const newOffset = getters.maxPageSize * (pageNumber - 1);
      commit('SET_OFFSET', newOffset);
      logger.debug(`Fetching page ${pageNumber} of document requests - offset has been updated to `, getters.offset);
      try {
        await dispatch('refreshCurrentPage');
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },

    deleteDocuments: async (
      {
        rootGetters, dispatch, commit, getters,
      }: ActionContext<State, any>,
      documentsIds: string[],
    ) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      logger.debug('Deleting document requests with the following interdocument ids: ', documentsIds);
      return new Api(process.env, rootGetters['authenticate/idToken'])
        .post('documentrequest/delete', { uuids: documentsIds })
        .then(async () => {
          logger.debug(`Entity documents ${documentsIds} deleted successfully`);
          // Compute the new offset as documents will be removed from the table
          const newOffset = getters.offset - documentsIds.length;
          commit('SET_OFFSET', newOffset < 0 ? 0 : newOffset);
          await dispatch('refreshDocumentRequestSummaryCounts');
          await dispatch('refreshCurrentPage');
          commit('SET_STORE_STATUS', allowedStates.IS_READY);
        })
        .catch((e) => {
          logger.error('Failed to delete documents: ', documentsIds);
          commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
          throw e;
        });
    },

    changeDocumentRequestsState: (
      {
        rootGetters, dispatch, getters, commit,
      }: ActionContext<State, any>,
      { ids, stage }: { ids: string[], stage: string },
    ) => {
      const body = { ids, stage };
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      return (new Api(process.env, rootGetters['authenticate/idToken']))
        .post('documentrequest/state', body).then(async (response) => {
          logger.debug('Re-init navigation to update counts in verification menu');
          // TODO: Check this works with pagination
          await dispatch('navigation/lazyInit', { disableCache: true }, { root: true });
          // Compute the new offset as documents will be removed from the table
          const newOffset = getters.offset - ids.length;
          commit('SET_OFFSET', newOffset < 0 ? 0 : newOffset);
          await dispatch('refreshDocumentRequestSummaryCounts');
          await dispatch('refreshCurrentPage');
          return response;
        }).catch((e) => {
          logger.error('Failed to change state of documents: ', ids, stage);
          commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
          throw e;
        });
    },

    requeueDocuments: (
      { rootGetters, dispatch }: ActionContext<State, any>,
      request: { document_request_id: string, document_type: string }[],
    ) => {
      logger.debug('Requeue documents', request);
      const body = { document_requests: request };
      return (new Api(process.env, rootGetters['authenticate/idToken']))
        .post('documentrequest/requeue', body).then((response) => {
          dispatch('navigation/lazyInit', { disableCache: true }, { root: true });
          return response;
        });
    },
  },
};

export default store;
