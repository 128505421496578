const columnHeaders = [
  {
    id: 'name',
    label: 'Name',
    columnWidth: '30%',
  },
  {
    id: 'akaCount',
    label: 'AKAs',
    columnWidth: '10%',
  },
  {
    id: 'metricIdentifierDisplayName',
    label: 'Type',
    columnWidth: '30%',
  },
  {
    id: 'timeUpdated',
    label: 'Last Edit',
    columnWidth: '30%',
  },
];

const DEFAULT_MAX_PAGE_SIZE = 20;

export { columnHeaders, DEFAULT_MAX_PAGE_SIZE };
