function isEnabled(flag: string): boolean {
  try {
    return process.env[flag].toLowerCase() === 'true';
  } catch {
    return false;
  }
}

function isSet(flag: string): boolean {
  try {
    return process.env[flag] && process.env[flag].length;
  } catch {
    return false;
  }
}

const FEATURE_FLAGS = {
  METRICS_INFINITE_SCROLL: true,
  MULTI_LINGUAL_SUPPORT: isEnabled('VUE_APP_MULTI_LINGUAL_SUPPORT'),
  USER_SETTINGS_UPLIFT: isEnabled('VUE_APP_USER_SETTINGS_UPLIFT'),
  CLIENT_SSO_ENABLED: isSet('VUE_APP_OAUTH_AUTHORIZE_URL'),
  USER_PROFILE_SETTINGS: false,
  DISABLE_PAGINATED_DOCUMENTS: localStorage.getItem('disable_paginated_documents') === 'true',
  ALLOW_DEFAULT_METRIC_LOADING: true,
  ENABLE_NEW_USER_ACCEPTANCE_WORK_FLOW: true,
  ENABLE_UX_REFRESH: localStorage.getItem('disable_ux_refresh')?.toLowerCase() !== 'true',
  ENABLE_SPLIT_DOCUMENT: true,
  ENABLE_SCHEMA_BUILDER_CLIENT: isEnabled('VUE_APP_SCHEMA_BUILDER_CLIENT'),
};

export default FEATURE_FLAGS;
