const errorMessages = {
  NO_NAME_ENTITY: 'An entity cannot have an empty name',
  FAILED_METRIC_VALIDATION: 'Metric validation failed',
  FAILED_CREATE_ENTITY: 'Failed to create entity',
  FAILED_DELETE_ENTITY: 'Failed to delete entity',
  FAILED_DELETE_ENTITIES: 'Failed to delete entities',
  FAILED_DELETE_ENTITY_RELATIONSHIPS: 'Failed to delete relationships',
  FAILED_DELETE_METRICS: 'Failed to delete metrics',
  FAILED_GENERATE_REPORT: 'Generating report failed',
  FAILED_EXPORT_METRICS: 'Failed to export metrics',
  FAILED_EXPORT_DOCUMENTS: 'Failed to export documents',
  FAILED_ENTITY_SEARCH: 'There was an error fetching the available entities',
  FAILED_ENTITY_FIND: 'Failed to retrieve entity information',
  FAILED_ENTITY_DOES_NOT_EXIST: '404 - Entity not found',
  FAILED_CHANGE_PASSWORD: 'Failed to change password',
  UPDATE_STATE: 'Failed to update document states',
  GET_USERS: 'Error loading users',
  GET_USER: 'Error loading user',
  CREATE_USER: 'Create user failed',
  UPDATE_USER: 'User update failed',
  REQUEUE_DOCUMENT: 'Re-processing document failed',
  REQUEUE_DOCUMENTS: 'Failed to re-process document(s)',
  FAILED_UPDATE_ENTITY: 'Failed to update entity',
  CANNOT_DUPLICATE_AKA: 'Could not add AKA with the same value as an existing AKA',
  FAILED_DOWNLOAD_DOCUMENT: 'Failed to download document',
};

const warningMessages = {
  CANNOT_COPY_ANNOTATION: 'No annotation selected to copy text from',
  CREATE_USER_MISSING_DATA: 'All required fields must be filled to create a new user',
  CREATE_USER_BAD_EMAIL: 'The email entered is not valid',
};

const infoMessages = {
  ANNOTATION_COPIED: 'Annotation text copied to clipboard',
};

const successMessages = {
  DELETED_ENTITY: 'Entity deleted successfully',
  DELETED_ENTITIES: 'Entities deleted successfully',
  DELETED_ENTITY_RELATIONSHIPS: 'Relationships deleted successfully',
  DELETED_METRICS: 'Metrics deleted successfully',
  EXPORTED_DOCUMENTS: 'Documents exported successfully',
  TRASHED_DOCUMENTS: 'Documents moved to trash',
  ARCHIVED_DOCUMENTS: 'Documents moved to archive',
  RESTORED_DOCUMENTS: 'Documents moved back to verification hub',
  CREATE_USER: 'New user created',
  UPDATE_USER: 'User updated',
  CHANGE_PASSWORD: 'Successfully changed password',
  UPDATED_ENTITY: 'Entity updated successfully',
};

export {
  errorMessages, warningMessages, infoMessages, successMessages,
};
