import { createStore } from 'vuex';
import entityNameMetrics from '@/store/entityNameMetrics';
import { logger } from '@/store/logger';
import annotations from './annotations';
import authenticate from './authenticate'; // eslint-disable-line import/no-cycle
import dashboard from './dashboard';
import documents from './documents/documents';
import documentStages from './documentStages';
import documentTypes from './documentTypes';
import documentRequest from './documentRequest';
import documentRequestQueue from './documentRequestQueue';
import entities from './entities';
import entitiesv2 from './entities/entitiesv2';
import entityTypes from './entityTypes/entityTypes';
import entity from './entity/entity';
import entityRelationships from './entityRelationships/entityRelationships';
import localisation from './localisation';
import processingDocuments from './processingDocuments';
import usage from './usage';
import users from './users';
import verifyDocument from './verifyDocument';
import verifyDocumentAlternativeAnswers from './verifyDocumentAlternativeAnswers';
import navigation from './navigation';
import metrics from './metrics/metrics';
import timePeriods from './timePeriods/timePeriods';
import schemaBuilder from './schemaBuilder/schemaBuilder';
import schemas from './schemas/schemas';

const strictModeEnabled = () => {
  // Strict mode should only be on in development and integrated.
  let strictMode = true;
  if (process.env.NODE_ENV === 'production') {
    strictMode = process.env.VUE_APP_API_ENDPOINT.includes('integrated');
  }
  logger.info('Strict mode: ', strictMode);
  return strictMode;
};

export default createStore({
  modules: {
    annotations,
    authenticate,
    dashboard,
    documents,
    documentStages,
    documentTypes,
    documentRequest,
    documentRequestQueue,
    entities,
    entitiesv2,
    entityTypes,
    entity,
    entityRelationships,
    entityNameMetrics,
    localisation,
    processingDocuments,
    usage,
    users,
    verifyDocument,
    verifyDocumentAlternativeAnswers,
    navigation,
    metrics,
    timePeriods,
    schemaBuilder,
    schemas,
  },
  strict: strictModeEnabled(),
});
