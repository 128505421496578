<template>
  <div>
    Entity metrics
  </div>
</template>
<script lang="ts" script>
</script>
<style lang="scss" scoped>

</style>
