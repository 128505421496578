<template>
  <div class="sign-in">
    <form
      class="login-form-container"
      @submit.prevent="onLogin"
    >
      <div class="login-input-group">
        <label
          class="form-label"
          for="email"
        ><b>Username</b></label>
        <input
          id="email"
          v-model="email"
          class="text-input"
          type="email"
          name="email"
          required
        >
      </div>
      <div class="login-input-group">
        <label
          class="form-label"
          for="password"
        ><b>Password</b></label>
        <input
          id="password"
          v-model="password"
          class="text-input"
          type="password"
          name="psw"
          required
        >
      </div>
      <div class="forgot-password-link">
        <router-link :to="{ name: 'RequestResetPassword'}">
          Forgot Password?
        </router-link>
      </div>
      <div class="login-button">
        <button
          class="btn-primary"
          type="submit"
        >
          Login
        </button>
      </div>
      <div
        v-if="ssoLoginUrl"
        class="sso-login-link"
      >
        <a :href="ssoLoginUrl">
          Log in via SSO
        </a>
      </div>
    </form>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { useToast } from 'vue-toastification';

export default {
  name: 'SignIn',
  components: {
  },
  data() {
    return {
      email: '',
      password: '',
      ssoLoginUrl: process.env.VUE_APP_OAUTH_AUTHORIZE_URL,
      toast: useToast(),
    };
  },
  methods: {
    ...mapActions({
      login: 'authenticate/login',
    }),
    onLogin() {
      const authData = {
        email: this.email,
        password: this.password,
      };
      this.login(authData)
        .then(() => {
          // Note, successful authentication may be for standard login
          // but also for a forced password change
          const msg = 'Email/password combination authenticated successfully';
          this.$log.info(msg);
          this.toast.success(msg);
        })
        .catch((e) => {
          this.$log.error('Login error:', e);
          this.toast.error('Email/password combination did not authenticate successfully');
        });
    },
  },
};
</script>
