import dayjs from 'dayjs';
import {
  SchemaMetric,
  Schema,
  SchemaMetricGroup,
} from './types';

/**
 * Parses a metric's filter group string, which is separated by '//' to represent filter layers.
 * The expected format of the filter group is one of the following:
 * - "subGroupName//subGroupFilterLabel//subGroupFilterValue"
 * - "subGroupName//subGroupFilterValue"
 *
 * The function extracts the subgroup name and optionally, other filter values.
 *
 * @param {string} filterGroup - The filter group string to parse.
 * @returns {Object} An object containing:
 *  - subGroupName {string}: The name of the subgroup.
 *  - timePeriod {string} [optional]: An optional time period filter, if present.
 */
export function splitMetricFilterGroup(filterGroup: string): {
  subGroupName: string | null,
  timePeriod: string | null,
} {
  const filters = filterGroup.split('//');
  // Filter groups may come in format as 'subGroupName//subGroupFilterLabel//subGroupFilterValue'
  // or 'subGroupName//subGroupFilterValue'.
  const [primaryFilter, secondaryFilter, tertiaryFilter] = filters;
  const subGroupName = primaryFilter ?? null;
  let timePeriod = null;
  if (tertiaryFilter && secondaryFilter.toLowerCase() === 'time period') {
    timePeriod = tertiaryFilter;
  } else if (secondaryFilter) {
    timePeriod = secondaryFilter;
  }
  return { subGroupName, timePeriod };
}

export const EMPTY_SCHEMA: Schema = {
  documentSchemaId: '',
  name: '',
  description: '',
  isTemplate: false,
  isPublished: false,
  reportingEntityType: '',
  versionedDocumentTypes: [],
  groups: [{
    displayName: 'Summary',
    isRepeatingGroup: false,
    calculatedTotals: false,
    generalMetrics: [],
    subGroups: [],
  }],
};
/**
 * This function merges metrics that belong to the general category with metrics
 * nested under specific sub-groups across multiple metric groups into one consolidated list.
 *
 * @param {SchemaMetricGroup[]} metricGroups - The list of metric groups, where each group contains
 *  general metrics and sub-groups containing metrics.
 * @returns {SchemaMetric[]} A combined list of all metrics from general and sub-group categories
 * across all metric groups.
 */
export function getAllMetrics(metricGroups: SchemaMetricGroup[]): SchemaMetric[] {
  return metricGroups.map((group) => [
    ...group.generalMetrics,
    ...group.subGroups.map((subGroup) => subGroup.metrics).flat(1),
  ]).flat(1);
}

export function createNewSchemaName() {
  const now = dayjs();

  const formattedDate = now.format('DD/MM/YYYY');
  const formattedTime = now.format('HH:mm:ss');

  return `Untitled Schema - ${formattedDate} ${formattedTime}`;
}

export function createDraftCopySchemaName(originalSchemaName: string) {
  const now = dayjs();

  const formattedDate = now.format('DD/MM/YYYY');
  const formattedTime = now.format('HH:mm:ss');

  return `Copy of ${originalSchemaName} - ${formattedDate} ${formattedTime}`;
}
