import { logger } from '@/store/logger';
import ValidationException from '../exceptions/ValidationException';

const backendToDocumentTypes = (documentTypes) => {
  logger.debug('transforming docTypes:', documentTypes);
  if (!documentTypes) {
    throw new ValidationException('Failed to retrieve document types');
  }
  const docTypesDic = {};

  documentTypes.forEach((docType) => {
    const { name } = docType;
    if (!name) {
      throw new ValidationException('Document type name not set');
    }
    if (name in docTypesDic) {
      throw new ValidationException('Retrieved duplicate document types');
    }
    const display = docType.display_name;
    if (!display) {
      throw new ValidationException(`Document display name not set for ${name}`);
    }

    docTypesDic[name] = { display };
  });

  return docTypesDic;
};

export default backendToDocumentTypes;
